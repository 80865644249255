




































import Vue from 'vue';
import ProjectForm from '@/views/projects/ProjectForm.vue';
import DescriptionCol from '@/components/list/DescriptionCol.vue';
import ListWithFilter from '@/components/list/ListWithFilter.vue';

export default Vue.extend({
  name: 'ListProjects',
  components: {
    ListWithFilter,
    ProjectForm,
    DescriptionCol,
  },
  computed: {
    searchUrl(): string {
      return `/org/${this.orgId}/project/info`;
    },
    orgId(): string {
      return this.$route.params.orgId;
    },
  },
  data() {
    return {
      extraColumns: [
        {
          name: 'description',
          label: 'Description',
          field: 'description',
          align: 'left',
          sortable: false,
        },
        {
          name: 'code',
          label: 'Number',
          field: 'code',
          align: 'left',
          sortable: false,
        },
        {
          name: 'divisions',
          label: 'Division',
          field: 'divisions',
          align: 'left',
          sortable: false,
        },
      ],
    };
  },
  methods: {
    async goToView({ id }: {id: string}) {
      await this.$store.dispatch('projectModule/loadDetails', {
        force: true,
        id,
      });
      await this.$router.push(`/org/${this.orgId}/project/${id}/bits`);
    },
    async onProjectCreate(response: { id: string; }, callback: () => Promise<void>) {
      this.$store.dispatch('projectModule/addProjectPreferences', response.id);
      await callback();
    },
  },
});
